exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-catalog-aluminum-alloys-tsx": () => import("./../../../src/pages/catalog/aluminum-alloys.tsx" /* webpackChunkName: "component---src-pages-catalog-aluminum-alloys-tsx" */),
  "component---src-pages-catalog-aluminum-tsx": () => import("./../../../src/pages/catalog/aluminum.tsx" /* webpackChunkName: "component---src-pages-catalog-aluminum-tsx" */),
  "component---src-pages-catalog-radiators-tsx": () => import("./../../../src/pages/catalog/radiators.tsx" /* webpackChunkName: "component---src-pages-catalog-radiators-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-contentful-news-url-tsx": () => import("./../../../src/pages/news/{contentfulNews.url}.tsx" /* webpackChunkName: "component---src-pages-news-contentful-news-url-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-pmf-tsx": () => import("./../../../src/pages/pmf.tsx" /* webpackChunkName: "component---src-pages-pmf-tsx" */),
  "component---src-pages-tech-info-tsx": () => import("./../../../src/pages/tech-info.tsx" /* webpackChunkName: "component---src-pages-tech-info-tsx" */),
  "component---src-pages-vacancies-tsx": () => import("./../../../src/pages/vacancies.tsx" /* webpackChunkName: "component---src-pages-vacancies-tsx" */)
}

